import React, { useContext, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { UserContext } from './UserContext';
import Cookies from 'js-cookie'

const Menu = () => {
    const { username } = useContext(UserContext);
    const [toggleMenu, setToggleMenu] = useState(false);

    function handleMenu() {
        setToggleMenu(prevToggleMenu => !prevToggleMenu);
    }

    const handleLogout = () => {
        // Supprimer le cookie en utilisant js-cookie
        Cookies.remove('token');
        // Appel à la fonction de déconnexion du contexte utilisateur
    };

    return (
        <header>
            <div className="host">
                <img src="https://static-cdn.jtvnw.net/jtv_user_pictures/d0f17299-7f49-49d5-8d77-4f90c2110ad0-profile_image-300x300.png" alt="logo" className="logo" />
                <h5>LaurentVerto</h5>
            </div>
            <h1><Link to="/genres" className='home'>GameStream</Link></h1>
            <div onClick={handleMenu} className={`menu-burger ${toggleMenu ? 'active' : ''}`}>
                <div class="burger"></div>
            </div>
            {toggleMenu &&
                <div class="menu">
                    <ul className='burger-back-menu'>
                        <li className='btn-menu'><Link to="/cadeau">Cadeau</Link></li>
                        <li className='btn-menu'><Link to="/prochainJeu">Prochain jeu</Link></li>
                        <li className='btn-menu'><Link to="/listeJeux">Liste des jeux</Link></li>

                         {username ? (
                        <>
                            <li className='btn-menu'><Link to="/espaceClient">{username}</Link></li>
                            <li className='btn-menu-dc'><Link to="#" onClick={handleLogout} >X</Link></li>
                        </>
                    ) : (
                        <>
                            <li className='btn-menu'><Link to="/connexion">Se connecter</Link></li>
                            <li className='btn-menu'><Link to="/inscription">S'inscrire</Link></li>
                        </>
                    )}
                    </ul>
                </div>
            }
            <nav>
                <ul>
                    <li className='btn-menu'><Link to="/cadeau">Cadeau</Link></li>
                    <li className='btn-menu'><Link to="/prochainJeu">Prochain jeu</Link></li>
                    <li className='btn-menu'><Link to="/listeJeux">Liste des jeux</Link></li>
                    {username ? (
                        <>
                            <li className='btn-menu'><Link to="/espaceClient">{username}</Link></li>
                            <li className='btn-menu-dc'><Link to="#" onClick={handleLogout} >X</Link></li>
                        </>
                    ) : (
                        <>
                            <li className='btn-menu'><Link to="/connexion">Se connecter</Link></li>
                            <li className='btn-menu'><Link to="/inscription">S'inscrire</Link></li>
                        </>
                    )}
                </ul>
            </nav>
        </header>
    );
};

export default Menu;
