import React, { useState, useContext } from 'react';
import { useNavigate, Link } from "react-router-dom";
import Cookies from 'js-cookie';
import API from './apiConfig';
import Menu from './Menu';

function Connexion() {
  const navigate = useNavigate();

  const [user, setUser] = useState({
    adresseMail: '',
    motDePasse: '',
  });

  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(API.USER_CONNEXION, {
        method: 'POST',
        headers: {
          "Content-type": "application/json",
        },
        credentials: 'include',
        body: JSON.stringify(user),
      });

      if (response.ok) {
        const { token, userData } = await response.json();
        Cookies.set('token', token)
        setMessage('Connexion réussie, Redirection en cours ...'); 

        
        setTimeout(() => {
          navigate(`/genres`);
      }, 1500);
      } else {
        // Handle authentication failure
        setMessage('Erreur d\'authentification'); // Replace with appropriate error message
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Une erreur est survenue'); // Replace with generic error message
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  return (
    <section>
      <form action="" onSubmit={handleSubmit}>
        <div className="champ">
          <label>Email</label>
          <input
            type="email"
            className="input"
            onChange={handleChange}
            required
            name="adresseMail"
          />
        </div>
        <div className="champ">
          <label>Mot de passe</label>
          <input
            type="password"
            className="input"
            onChange={handleChange}
            required
            name="motDePasse"
          />
        </div>
        <button className="btn btn-register">Se connecter</button>
        <p>{message}</p>
      </form>
      <Link to="/inscription" className="link">
        Pas de compte ?
      </Link>
    </section>
  );
}

export default Connexion;
