import React, { useState, useContext, useEffect } from "react";
import MenuAdmin from "./MenuAdmin";
import API from "./apiConfig";

function JeuxEnCours() {
    const [jeux, setJeux] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedPlatform, setSelectedPlatform] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(API.LISTE_GENRE_SPE);
                const data = await response.json();
                setJeux(data);
            } catch (error) {
                console.error("Erreur lors de la récupération des jeux :", error);
            }
        };

        fetchData();
    }, []);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handlePlatformSelection = async (platform, jeu) => {
        setSelectedPlatform(platform);
    
        // Mettre à jour le statut dans la base de données
        try {
            const response = await fetch(`/jeu/${jeu._id}/status`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ statut: platform }),
            });
    
            if (!response.ok) {
                throw new Error("Erreur lors de la mise à jour du statut");
            }
    
            // Mettre à jour l'état local des jeux
            const updatedJeu = await response.json();
            setJeux((prevJeux) =>
                prevJeux.map((j) => (j._id === jeu._id ? updatedJeu : j))
            );
        } catch (error) {
            console.error("Erreur lors de la mise à jour du statut :", error);
        }
    };

    // Filtrer les jeux par la requête de recherche
    const jeuxFiltres = jeux.filter((jeu) =>
        jeu.nomJeu.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <section>
            <MenuAdmin />
            <h4>Liste du prochain jeu en stream</h4>
            <div className="champ">
                <input
                    type="text"
                    placeholder="Rechercher un jeu..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="input"
                />
            </div>
            <article className="liste-jeux-xs">
                <div className="liste-podium">
                    <table>
                        <thead>
                            <tr>
                                <th>Position</th>
                                <th>Cover</th>
                                <th>Nom du jeu</th>
                                <th>Genre du jeu</th>
                                <th>Statut</th>
                                <th>Actions</th> {/* Ajout de la colonne Actions */}
                            </tr>
                        </thead>
                        <tbody>
                            {jeuxFiltres.map((jeu, index) => (
                                <tr key={jeu._id}>
                                    <td>{index + 1}</td>
                                    <td className="cover"><img src={jeu.urlBackground} alt="" /></td>
                                    <td>{jeu.nomJeu}</td>
                                    <td>{jeu.genre}</td>
                                    <td>
                                        {jeu.statut === 'twitch' && (
                                            <span>En stream sur Twitch</span>
                                        )}
                                        {jeu.statut === 'youtube' && (
                                            <span>Disponible sur YouTube</span>
                                        )}
                                        {jeu.statut === 'inactive' && (
                                            <span>Inactif</span>
                                        )}
                                    </td>
                                    <td>
                                        <button className="btn-twitch" onClick={() => handlePlatformSelection('twitch', jeu)} style={{ marginRight: '10px' }}>Twitch</button>
                                        <button className="btn-youtube" onClick={() => handlePlatformSelection('youtube', jeu)} style={{ marginRight: '10px' }}>YouTube</button>
                                        <button className="btn-inactive" onClick={() => handlePlatformSelection('inactive', jeu)}>Inactive</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </article>
        </section>
    );
}

export default JeuxEnCours;
