import './App.css';
import Navigation from './Navigation';
import { UserProvider } from './UserContext';

function App() {
  return (
    <UserProvider>
      <Navigation />
    </UserProvider>
  );
}

export default App;
