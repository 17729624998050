import React from "react";
import MenuClient from "./MenuClient";

function EspaceClient() {
    return(
        <section className="client">
            <MenuClient />
            <h2>Bienvenue sur l'Espace Client</h2>
        </section>
    )
}

export default EspaceClient;