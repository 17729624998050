import React, { useEffect, useState, useContext } from "react";
import Cookies from 'js-cookie';
import { UserContext } from './UserContext';
import API from "./apiConfig";



function Cadeau() {
    const [cadeau, setCadeau] = useState([]);

    const [token, setToken] = useState(null);
const { username, setUsername } = useContext(UserContext);

    useEffect(() => {
        const fetchData = async () => {
            if (token) {
                try {
                    const response = await fetch(`${API.USER_TOKEN}/${token}`);
                    const data = await response.json();
                    setUsername(data.username); // Assuming the response has a username field
                } catch (error) {
                    console.error("Server error:", error);
                }
            }
        };

        fetchData();
    }, [token]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(API.CADEAU_ACTIF);
                const data = await response.json();
                setCadeau(data);
            } catch (error) {
                console.error("Server error:", error);
            }
        };

        fetchData();
    }, []);

    const handleInscription = async () => {
        if (cadeau.length === 0) return;

        try {
            const response = await fetch(`${API.CADEAU_INSCRIPTION}/${cadeau[0]._id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username }),
            });

            if (response.ok) {
                const updatedCadeau = await response.json();
                setCadeau([updatedCadeau]); // Mettre à jour l'état avec le cadeau mis à jour
            } else {
                console.error("Failed to register");
            }
        } catch (error) {
            console.error("Server error:", error);
        }
    };

    if (cadeau.length === 0) {
        return <div>Loading...</div>;
    }

    return(
        <section>
            <h4>Cadeaux</h4>
            <article className="objectif">
                <progress value={cadeau[0].inscrit.length} max={cadeau[0].nombreParticipant}></progress>
                <p className="label-progress">{cadeau[0].inscrit.length}/{cadeau[0].nombreParticipant} </p>
            </article>
            <button onClick={handleInscription} className="btn-register">S'inscrire</button>
            <article className="cadeau" style={{ backgroundImage: `url(${cadeau[0].urlBackground})` }}>
                <p className="prix-cadeau">{cadeau[0].prixCadeau} €</p>
                <p className="nbr-participant">1 Clé</p>
            </article>
        </section>
    );
}

export default Cadeau;
