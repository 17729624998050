import React, { useEffect, useState } from "react";
import MenuAdmin from "./MenuAdmin";
import Cookies from 'js-cookie';
import API from "./apiConfig";

function TirageSort() {
    const [cadeau, setCadeau] = useState([]);
    const [token, setToken] = useState(null);
    const [username, setUsername] = useState('');
    const [gagnant, setGagnant] = useState('');

    useEffect(() => {
        // Récupère le token du cookie
        const storedToken = Cookies.get('token');
        setToken(storedToken);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (token) {
                try {
                    const response = await fetch(`${API.USER_TOKEN}/${token}`);
                    const data = await response.json();
                    setUsername(data.username); // Assuming the response has a username field
                } catch (error) {
                    console.error("Server error:", error);
                }
            }
        };

        fetchData();
    }, [token]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(API.CADEAU_ACTIF);
                const data = await response.json();
                setCadeau(data);
            } catch (error) {
                console.error("Server error:", error);
            }
        };

        fetchData();
    }, []);

    const handleInscription = async () => {
        if (cadeau.length === 0) return;

        try {
            const response = await fetch(`${API.CADEAU_INSCRIPTION}/${cadeau[0]._id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username }),
            });

            if (response.ok) {
                const updatedCadeau = await response.json();
                setCadeau([updatedCadeau]); // Mettre à jour l'état avec le cadeau mis à jour
                const randomIndex = Math.floor(Math.random() * updatedCadeau.inscrit.length);
                setGagnant(updatedCadeau.inscrit[randomIndex]); // Sélectionne un gagnant au hasard parmi les participants inscrits
            } else {
                console.error("Failed to register");
            }
        } catch (error) {
            console.error("Server error:", error);
        }
    };

    
    if (cadeau.length === 0) {
        return <div>Loading...</div>;
    }
    return (
        <section>
            <MenuAdmin />
            <h4>Tirage au sort</h4>
            <article className="objectif">
                <progress value={cadeau[0].inscrit.length} max={cadeau[0].nombreParticipant}></progress>
                <p className="label-progress">{cadeau[0].inscrit.length}/{cadeau[0].nombreParticipant} </p>
            </article>
            <article className="cadeau-xs" style={{ backgroundImage: `url(${cadeau[0].urlBackground})` }}>
                <p className="prix-cadeau">{cadeau[0].prixCadeau} €</p>
                <p className="nbr-participant">1 Clé</p>
            </article>
            <button onClick={handleInscription} className="btn-register">Tirage au sort</button>
            {gagnant && (
                <div className="gagnant">
                    <h5>Gagnant : 
                        {gagnant}</h5>
                </div>
            )}
        </section>
    )
}

export default TirageSort;
