import React from "react";
import MenuAdmin from "./MenuAdmin";

function EspaceAdmin() {
    return(
        <section>
            <MenuAdmin />
            <h2>Bienvenue sur l'Espace Admin</h2>
        </section>
    )
}

export default EspaceAdmin;