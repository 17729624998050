import React, { useEffect, useState, useContext } from "react";
import { Navigate } from 'react-router-dom';
import { UserContext } from './UserContext';
import API from "./apiConfig";



const PrivateRoute = ({ element, redirectTo, requiredRole, ...rest }) => {
  const { username, role } = useContext(UserContext);

  

  

  if (!username || (requiredRole && role !== requiredRole)) {
    return <Navigate to={redirectTo} />;
  }

  return element;
};

export default PrivateRoute;