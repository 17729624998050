// src/utils/apiConfig.js
const apiUrl = process.env.REACT_APP_API_URL;

const API = {
    CREATE_CADEAU: `${apiUrl}/cadeau/create-cadeau`,
    CREATE_GENRE: `${apiUrl}/genre/create-genre`,
    CREATE_JEU: `${apiUrl}/jeu/create-jeu`,
    LISTE_GENRES: `${apiUrl}/genre/liste-genres`,
    LISTE_GENRE_SPE: `${apiUrl}/jeu/liste-jeux`,
    CADEAU_ACTIF: `${apiUrl}/cadeau/cadeau-actif`,
    CADEAU_INSCRIPTION: `${apiUrl}/cadeau/inscription-cadeau`,
    // Ajoutez d'autres endpoints ici au besoin
    USER_DETAILS: `${apiUrl}/user/details-user`,
    LIKE_JEU: `${apiUrl}/jeu/like`,
    USER_TOKEN: `${apiUrl}/user`, //token
    USER_CONNEXION: `${apiUrl}/user/connect-user`,
    USER_INFOS: `${apiUrl}/user/infos`,
    USER_USERNAME_UPDATE: `${apiUrl}/user/update-username`,
    USER_DECO: `${apiUrl}/user/logout`,
    USER_CREATION: `${apiUrl}/user/create-user`,

};

export default API;
