import React, { createContext, useEffect, useState } from 'react';
import API from './apiConfig';
import Cookies from 'js-cookie';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [userData, setUserData] = useState({ username: '', role: '', isLoading: true });

    useEffect(() => {
        const fetchUserData = async () => {
            const token = Cookies.get('token');
            if (token) {
                try {
                    const response = await fetch(API.USER_DETAILS, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                        credentials: 'include'
                    });
        
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
        
                    const data = await response.json();
                    setUserData({ username: data.nomUtilisateur, role: data.role, isLoading: false });
                } catch (error) {
                    console.error("Erreur lors de la récupération des données utilisateur :", error);
                    setUserData({ username: '', role: '', isLoading: false });
                }
            } else {
                setUserData({ username: '', role: '', isLoading: false });
            }
        };
        
        fetchUserData();

        const intervalId = setInterval(fetchUserData, 1000);
        
        return () => clearInterval(intervalId);
    }, []);

    return (
        <UserContext.Provider value={userData}>
            {children}
        </UserContext.Provider>
    );
};
