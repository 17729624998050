import React from "react";
import { Link } from 'react-router-dom';

function MenuAdmin() {
    return(
        <div class="menu-admin">
            <h4>Espace Admin</h4>
            <article>
                <ul>
                    <li><Link to="/TirageAuSort">Tirage au sort</Link></li>
                    <li><Link to="/JeuxEnCours">Jeux en cours</Link></li>
                    <li><Link to="/addGame">Ajouter un jeu</Link></li>
                    <li><Link to="/addGift">Ajouter un cadeau</Link></li>
                    <li><Link to="/addGenre">Ajouter un genre</Link></li>
                </ul>
            </article>
            <a href="" class="btn-disconnect-admin">Deconnexion</a>
        </div>
    )
}

export default MenuAdmin;