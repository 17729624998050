import React, { useEffect, useState } from "react";
import MenuAdmin from "./MenuAdmin";
import API from "./apiConfig";

function AjouterJeu() {
    const [genres, setGenres] = useState([]);
    const [jeu, setJeu] = useState({
        nomJeu: '',
        urlBackground: '',
        genre: '',
        likes:[]
    });
    const [message, setMessage] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(API.LISTE_GENRES);
                const data = await response.json();
                setGenres(data);
            } catch (error) {
                console.error("Server error:", error);
            }
        };

        fetchData();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(API.CREATE_JEU, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(jeu)
            });
            const data = await response.json();

            if (response.status === 201) {
                setMessage("Création du jeu réussie");
            } else {
                setMessage("Erreur lors de la requête");
            }
        } catch (error) {
            setMessage("Une erreur est survenue");
        }
    };

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setJeu({ ...jeu, [name]: value });
    };

    return (
        <section>
            <MenuAdmin />
            <form action="" onSubmit={handleSubmit}>
                <div className="champ">
                    <label htmlFor="nomJeu">Nom du jeu</label>
                    <input type="text" className="input" onChange={handleChange} name="nomJeu" id="nomJeu" />
                </div>
                <div className="champ">
                    <label htmlFor="urlBackground">URL background</label>
                    <input type="text" className="input" onChange={handleChange} name="urlBackground" id="urlBackground" />
                </div>
                <div className="champ">
                    <label htmlFor="genre">Genre</label>
                    <select name="genre" id="genre" onChange={handleChange}>
                        <option value="">Sélectionnez un genre</option>
                        {genres.map((genre) => (
                            <option key={genre._id} value={genre.nomGenre}>{genre.nomGenre}</option>
                        ))}
                    </select>
                </div>
                <button className="btn btn-register">Ajouter le jeu</button>
                <p>{message}</p>
            </form>
        </section>
    );
}

export default AjouterJeu;
