import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import API from './apiConfig';




function Register() {

    const navigate = useNavigate()

    const [user, setUser] = useState({
        adresseMail: '',
        nomUtilisateur: '',
        motDePasse: ''
    })

    const [message, setMessage] = useState('');

    const handlesubmit = async (e) => {
        e.preventDefault()


        try {
            const response = await fetch(API.USER_CREATION, { // ici on créer la constante qui va récuperer la reponse 
                method: 'POST',
                headers: {
                    "Content-type": "application/json",
                },
                credentials: 'include',
                body: JSON.stringify(user) // ici il transformera donc user en fichier json qui sera interprété par le serveur 
            });
            const data = await response.json()

            if (response.status === 201) {
                setMessage("Création réussie")
                setTimeout(() => {
                    navigate(`/connexion`);
                }, 800);
            } else {
                setMessage("Erreur requete")
            }


        } catch (error) {
            setMessage("Une erreur est survenue") // donc ici on a simplement attrivbuer un mesage d'erreur coté client pour que le client sache qu'il y a eu ou non un probleme
        }

    }

    const handleChange = (e) => { // pour que le user puisse mettre a jour les infos depuis le front il faut lui dire que quand cette valeur change change la en direct dans le user
        e.preventDefault();
        const {name, value} = e.target // donc ici on recupere le name et la valeur de celui qui est cliquer donc e.target 
        setUser({...user, [name]:value}); // ici on lui dit de changer la valeur de user , celon le nom et la valeur

   }

    return (
        <section>
            <form action="" onSubmit={handlesubmit}>
                <div class="champ">
                    <label for="">Pseudo</label>
                    <input type="text" className='input' name='nomUtilisateur' onChange={handleChange} required/>
                </div>
                <div class="champ">
                    <label for="">Email</label>
                    <input type="mail" className='input' name='adresseMail' onChange={handleChange} required />
                </div>
                <div class="champ">
                    <label for="">Mot de passe</label>
                    <input type="password" className='input' name='motDePasse' onChange={handleChange} required />
                </div>
                {/* <div class="champ">
                    <label for="">Confirmer votre mot de passe</label>
                    <input type="text" className='input' />
                </div> */}
                <button className='btn btn-register'>S'inscrire</button>
                <p>{message}</p>
            </form>
            <a href="" className='link' >Déjà un compte ?</a>
        </section>
    )
}

export default Register;