import React, { useState } from "react";
import MenuAdmin from "./MenuAdmin";
import API from "./apiConfig";

function AjouterGenre() {


    const [genre, setGenre] = useState({
        nomGenre: '',
        urlBackground: '',
        urlPersonnage: ''
    })

    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault()


        try {
            const response = await fetch(API.CREATE_GENRE, { // ici on créer la constante qui va récuperer la reponse 
                method: 'POST', //on précise ici la methode donc ici ce sera un post comme attendu coté serveur
                headers: {
                    "Content-type": "application/json" // on lui indique que ce sera en json
                },
                body: JSON.stringify(genre) // ici il transformera donc user en fichier json qui sera interprété par le serveur 
            });
            const data = await response.json()

            if (response.status === 201) {
                setMessage("Création du genre réussie")
            } else {
                setMessage("Erreur requete")
            }


        } catch (error) {
            setMessage("Une erreur est survenue") // donc ici on a simplement attrivbuer un mesage d'erreur coté client pour que le client sache qu'il y a eu ou non un probleme
        }

    }

    const handleChange = (e) => { // pour que le user puisse mettre a jour les infos depuis le front il faut lui dire que quand cette valeur change change la en direct dans le user
        e.preventDefault();
        const { name, value } = e.target // donc ici on recupere le name et la valeur de celui qui est cliquer donc e.target 
        setGenre({ ...genre, [name]: value }); // ici on lui dit de changer la valeur de user , celon le nom et la valeur
    }

    return (
        <section>
            <MenuAdmin />
            <form action="" onSubmit={handleSubmit}>
                <div class="champ" >
                    <label for="">Nom du genre</label>
                    <input type="text" className='input' required name="nomGenre" onChange={handleChange}/>
                </div>
                <div class="champ">
                    <label for="">URL background</label>
                    <input type="text" className='input' required name="urlBackground" onChange={handleChange} />
                </div>
                <div class="champ">
                    <label for="">URL personnage</label>
                    <input type="text" className='input' required name="urlPersonnage" onChange={handleChange}/>
                </div>
                <button className='btn btn-register'>Ajouter le genre</button>
                <p>{message}</p>
            </form>
        </section>
    )
}

export default AjouterGenre;