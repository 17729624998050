import React, { useEffect, useState } from "react";
import MenuClient from "./MenuClient";
import API from "./apiConfig";

//FIXME: Probleme de requete ici je pense

function InfosPersonnelles() {

    const [message, setMessage] = useState('');
    const token = document.cookie.split(';').find(cookie => cookie.trim().startsWith('token=')).split('=')[1];

    const [userDetails, setUserDetails] = useState({
        adresseMail: '',
        nomUtilisateur: ''
    });
    const [newNomUtilisateur, setNewNomUtilisateur] = useState('');

    useEffect(() => {
        fetch(API.USER_INFOS, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                setUserDetails(data);
                setNewNomUtilisateur(data.nomUtilisateur);
            })
            .catch((error) => {
                console.error('Error fetching user info:', error);
            });
    }, []);

    const handleInputChange = (event) => {
        setNewNomUtilisateur(event.target.value);
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        const updatedUser = {
            adresseMail: userDetails.adresseMail,
            nomUtilisateur: newNomUtilisateur
        };

        fetch(API.USER_USERNAME_UPDATE, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(updatedUser)
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                setMessage('Tout a été modifié avec succès')
                return response.json();

            })
            .then((data) => {
                setUserDetails(data);
            })
            .catch((error) => {
                console.error('Error updating user info:', error);
            });
    };

    console.log(newNomUtilisateur);

    return (
        <section className="client">
            <MenuClient />
            <article>

                <h4>Informations personnelles</h4>
                <form onSubmit={handleFormSubmit}>
                    <div className="champ">
                        <label>Email</label>
                        <p>{userDetails.adresseMail}</p>
                    </div>
                    <div className="champ">
                        <label>Nom d'utilisateur</label>
                        <input
                            type="text"
                            name="nomUtilisateur"
                            value={newNomUtilisateur}
                            onChange={handleInputChange}
                        />
                    </div>
                    <button className="btn btn-register" type="submit">
                        Modifier les infos
                    </button>
                    <p>{message}</p>
                </form>
            </article>
        </section>
    );
}

export default InfosPersonnelles;
