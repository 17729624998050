import React from "react";
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import EspaceAdmin from "./EspaceAdmin";
import Menu from "./Menu";
import Register from "./Register";
import Connexion from "./Connexion";
import Genres from "./Genres";
import AjouterGenre from "./AjouterGenre";
import Aventures from "./Aventures";
import AjouterJeu from "./AjouterJeu";
import ProchainJeu from "./ProchainJeu";
import AjouterCadeau from "./AjouterCadeau";
import Cadeau from "./Cadeau";
import ListeJeux from "./ListeJeux";
import TirageSort from "./TirageSort";
import JeuxEnCours from "./JeuxEnCours";
import PrivateRoute from "./PrivateRoute";
import Socials from "./Socials";
import EspaceClient from "./EspaceClient";
import InfosPersonnelles from "./InfosPersonnelles";
import { UserProvider } from "./UserContext";

function Navigation() {
    return (
        <UserProvider>
            <Router>
                <Menu />
                <Routes>
                    <Route path="/espaceAdmin" element={<PrivateRoute element={<EspaceAdmin />} redirectTo="/" requiredRole="admin" />} />
                    <Route path="/addGenre" element={<PrivateRoute element={<AjouterGenre />} redirectTo="/" requiredRole="admin" />} />
                    <Route path="/addGame" element={<PrivateRoute element={<AjouterJeu />} redirectTo="/" requiredRole="admin" />} />
                    <Route path="/prochainJeu" element={<PrivateRoute element={<ProchainJeu />} redirectTo="/connexion"  />} />
                    <Route path="/addGift" element={<PrivateRoute element={<AjouterCadeau />} redirectTo="/" requiredRole="admin" />} />
                    <Route path="/TirageAuSort" element={<PrivateRoute element={<TirageSort />} redirectTo="/" requiredRole="admin" />} />
                    <Route path="/genres" element={<PrivateRoute element={<Genres />} redirectTo="/connexion" />} />
                    <Route path="/espaceClient" element={<PrivateRoute element={<EspaceClient />} redirectTo="/connexion" />} />
                    <Route path="/infosPersonnelles" element={<PrivateRoute element={<InfosPersonnelles />} redirectTo="/connexion" />} />
                    <Route path="/genres/:genre" element={<PrivateRoute element={<Aventures />} redirectTo="/connexion" />} />
                    <Route path="/cadeau" element={<PrivateRoute element={<Cadeau />} redirectTo="/connexion" />} />
                    <Route path="/listeJeux" element={<PrivateRoute element={<ListeJeux />} redirectTo="/connexion" />} />
                    <Route path="/JeuxEnCours" element={<PrivateRoute element={<JeuxEnCours />} redirectTo="/connexion" />} />
                    <Route path="/inscription" element={<Register />} />
                    <Route path="/connexion" element={<Connexion />} />
                    <Route path="/" element={<Connexion />} />
                </Routes>
                <Socials />
            </Router>
        </UserProvider>
    );
}

export default Navigation;
