import React from "react";
import { Link } from 'react-router-dom';

function MenuClient() {
    return(
        <div class="menu-admin">
            <h4>Espace Client</h4>
            <article>
                <ul>
                    <li><Link to="/infosPersonnelles">Infos personnelles</Link></li>
                </ul>
            </article>
            <a href="" class="btn-disconnect-admin"></a>
        </div>
    )
}

export default MenuClient;