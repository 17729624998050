import React, { useState, useContext, useEffect } from "react";
import { UserContext } from './UserContext';
import API from "./apiConfig";

function ListeJeux() {
    const { username } = useContext(UserContext);
    const [jeux, setJeux] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(API.LISTE_GENRE_SPE);
                const data = await response.json();
                setJeux(data);
            } catch (error) {
                console.error("Erreur lors de la récupération des jeux :", error);
            }
        };

        fetchData();
    }, []);

    const handleLike = async (jeuId) => {
        try {
            const response = await fetch(API.LIKE_JEU, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ jeuId, username }),
            });

            if (!response.ok) {
                throw new Error('Erreur lors de l\'envoi du like');
            }

            const responseData = await response.json();

            // Mettre à jour les likes localement
            setJeux((prevJeux) => prevJeux.map((jeu) =>
                jeu._id === jeuId ? { ...jeu, likes: responseData.likes } : jeu
            ));
        } catch (error) {
            console.error("Erreur lors de l'envoi du like:", error);
        }
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    // Filtrer les jeux par la requête de recherche
    const jeuxFiltres = jeux.filter((jeu) =>
        jeu.nomJeu.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Trier les jeux par nombre de likes décroissant
    const jeuxTries = [...jeuxFiltres].sort((a, b) => b.likes.length - a.likes.length);

    return (
        <section className="game-list">
            <h4>Liste des jeux</h4>
            <div class="champ">
                <input
                    type="text"
                    placeholder="Rechercher un jeu..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="input"
                />
            </div>
            <article className="liste-jeux">
                <div className="liste-podium">
                    <table>
                        <thead>
                            <tr>
                                <th>Position</th>
                                <th>Cover</th>
                                <th>Nom</th>
                                <th>Genre</th>
                                <th>Likes</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {jeuxTries.slice(0).map((jeu, index) => (
                                <tr key={jeu._id}>
                                    <td>{index + 1}</td>
                                    <td className="cover"><img src={jeu.urlBackground} alt="" /></td>
                                    <td>{jeu.nomJeu}</td>
                                    <td>{jeu.genre}</td>
                                    <td>{jeu.likes.length}</td>
                                    <td>
                                        <svg
                                            onClick={(event) => {
                                                const target = event.currentTarget;
                                                if (target) {
                                                    target.classList.add("clicked"); // Ajoute la classe "clicked" pour déclencher l'animation
                                                    handleLike(jeu._id); // Exécute la fonction handleLike pour effectuer la requête
                                                    setTimeout(() => {
                                                        target.classList.remove("clicked"); // Supprime la classe "clicked" après un court délai
                                                    }, 300); // Délai correspondant à la durée de l'animation en millisecondes
                                                }
                                            }}
                                            className="like-icon"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="18"
                                            viewBox="0 0 20 18"
                                            fill={jeu.likes.includes(username) ? "red" : "#e0e0e0"}
                                            stroke="none"
                                        >
                                            <path d="M10 5H10.5C10.5 3.74429 11.0137 2.64461 11.8261 1.83097C12.6689 1.01207 13.7715 0.5 15 0.5C16.2557 0.5 17.3554 1.01367 18.1691 1.82617C18.9879 2.66894 19.5 3.77156 19.5 5C19.5 6.25695 18.9853 7.35756 18.1714 8.17145L10 16.3429L1.83102 8.17392C1.01209 7.33114 0.5 6.22848 0.5 5C0.5 3.74429 1.01365 2.64461 1.82614 1.83097C2.6689 1.01207 3.77155 0.5 5 0.5C6.2557 0.5 7.35537 1.01365 8.16901 1.82612C8.98792 2.66889 9.5 3.77154 9.5 5H10Z" fill={jeu.likes.includes(username) ? "red" : "#e0e0e0"} stroke="none" />
                                        </svg>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </article>
        </section>
    );
}

export default ListeJeux;
