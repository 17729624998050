import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import API from "./apiConfig";


function Genres() {

   
    

    const [genres, setGenres] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(API.LISTE_GENRES);
                const data = await response.json();
                console.log(data); // Vérifiez les données reçues
                setGenres(data);
            } catch (error) {
                console.error("Server error:", error);
            }
        };
    
        fetchData();
    }, []);

    

    return (
        <section>
            <h4>Choisir un genre</h4>
            <article className="liste">

            {genres.map((genre) => (
                <Link to={`/genres/${genre.nomGenre}`}><article className="liste-genres" key={genre._id}>
                    <div className="card-genres" style={{ backgroundImage: `url(${genre.urlBackground})` }}>
                        <h3>{genre.nomGenre}</h3>
                        <div className="overlay" style={{ backgroundImage: `url(${genre.urlPersonnage})` }}>
                        </div>
                    </div>
                </article></Link>
            ))}
            </article>

            <Link to="/prochainJeu" className="btn-choose-game">Choisir le prochain jeu du stream</Link>

        </section>
    )
}

export default Genres;